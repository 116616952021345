import React, { useState, useEffect, useRef } from 'react'

type Props = { src: string, hex: string }

function ImageWithLoading({ src, hex }: Props) {

    const [loaded, setLoaded] = useState<boolean>(false)
    const fake = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        setLoaded(false)
       
        if (fake) {
            if (fake.current) {
                if (hex) {
                    fake.current.style.backgroundColor = hex
                }
            }
        }
    }, [src, hex])

    return (
        <div ref={fake} className='w-[250px] h-[250px] rounded-full'>
            {!loaded ? (
                    <div  className={'rounded-full w-[250px] h-[250px] shadow-lg  animate-pulse'}></div>
            ) : null}
            <img
                src={src}
                className="rounded-full w-[250px] md:w-[250px]"
                alt={"range"}
                style={!loaded ? { visibility: 'hidden' } : {}}
                onLoad={() => setLoaded(true)}
            />
        </div>
    )
}

export default ImageWithLoading