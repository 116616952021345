import React, { createContext, useState, useContext, useEffect } from 'react';
import { User } from '../types/types';

//cookie
import Cookies from 'js-cookie'

// service
import { getUserInfoByAccessToken } from '../service/auth';

//types
interface AuthContextProps {
  children: JSX.Element
}

interface AppContextInterface {
  user: User | undefined,
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>,
  isAuthenticated: boolean,
  logout: () => void,
  loading: boolean
}


// context init
export const AuthContext = createContext({} as AppContextInterface);
export const useAuthContext = () => useContext(AuthContext);

// Provider
function AuthContextProvider({ children }: AuthContextProps) {


  //cookie
  let COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME

  // state    
  const [user, setUser] = useState<User | undefined>(undefined)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const initUser = async (accessToken: string) => {
    let user: User | undefined = await getUserInfoByAccessToken(accessToken)
    if (user) {
      setUser(user)
    }
    setLoading(false)
  }



  useEffect(() => {
    if (user) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  }, [user])

  //only first time
  useEffect(() => {
    setLoading(true)

    let accessToken = Cookies.get(COOKIE_NAME!);

    if (accessToken) {
      initUser(accessToken)
    } else {
      setLoading(false)
    }
  }, [COOKIE_NAME])

  const logout = () => {
    setUser(undefined)
    Cookies.remove(COOKIE_NAME!)
  }


  return (
    <AuthContext.Provider value={{
      user,
      isAuthenticated,
      loading,
      setUser,
      logout
    }}>
      {
        children
      }
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;