import api from '../service/index'
import { Article } from '../types/types';

export const getArticleByTag = async (tag?: string): Promise<Article[] | undefined> => {

    let filter = "";
    if (tag){
        filter = "?tag="+tag
    }

    try {
        let response = await api.get("/shopify/article"+filter)

        if (response) {
            let allArticle: Article[] = response.data;
            return allArticle;
        }else{
            return undefined
        }

        


    } catch (e) {
        return undefined
    }
}