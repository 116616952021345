import React from 'react'
import { useTranslation } from 'react-i18next'

const LANGUAGE_DATA: any = {
    it: {
        textToShow: "IT",
        icon: "&#127470;&#127481;"
    },
    en: {
        textToShow: "EN",
        icon: "&#127468;&#127463;"

    }
}

function LanguageSwitch() {

    const { i18n } = useTranslation()
    const language = i18n.languages;

    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang)
    }
    return (
        <div>
            <select value={i18n.language} onChange={(event) => changeLanguageHandler(event.target.value)} className='bg-white border  rounded-full px-4 py-2'>

                {
                    language.map((lan) => {
                        return <option
                            value={lan}
                            dangerouslySetInnerHTML={{ __html: LANGUAGE_DATA[lan].icon }}
                        />

                    })
                }

            </select>

        </div>
    )
}

export default LanguageSwitch