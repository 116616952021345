import React from 'react'
import Section from '../section/Section'

type Props = {
    nextAnswer: any,
    nextAnswerValue: string | null
    previousAnswer: any,
    previousAnswerValue: string | null,
    selectedAnswerValue: string | undefined,
    isLastQuestion: boolean | undefined
}

function FooterButton({ nextAnswer, previousAnswer, selectedAnswerValue, nextAnswerValue, previousAnswerValue, isLastQuestion }: Props) {
    
    const handleKeyDown = (event:any, nextAnswerValue:any, isLastQuestion:any) => {
        if (event.key === 'Enter') {
          console.log('do validate')
        }
      }

    return (
        <div className='fixed bg-white shadow-xl rounded-t-xl p-4  bottom-0 w-full'>
            <Section>
                <div className='grid grid-cols-3 md:grid-cols-3 gap-1 text-center'>
                    <button  onClick={() => previousAnswer(previousAnswerValue)} className='w-full flex justify-center text-center text-white bg-primary rounded-full py-2 px-4 disabled:opacity-40'>{previousAnswerValue || previousAnswer === "" ? "Precedente":"Home"}</button>
                    <div></div>
                    <button disabled={selectedAnswerValue ? false : true} onClick={() => nextAnswer(nextAnswerValue,isLastQuestion)} onKeyDown={(e)=>handleKeyDown(e,
                        
                        
                        nextAnswerValue,isLastQuestion)} className='w-full text-center text-white bg-primary rounded-full py-2 px-4 disabled:opacity-40'>{nextAnswerValue ? "Prossima": "Risultato"}</button>
                </div>
            </Section>
        </div>
    )
}

export default FooterButton