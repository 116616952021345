import api from '../service/index'
import { ResultSeasonTest, Season, UpdateProfile } from '../types/types'
import { ApiError } from '../utils/error'


export const getResultSeasonTest = async (data:ResultSeasonTest) : Promise<Season>  => {

    try {
        let response = await api.post("/test/season",
            data.test_response,
            {
                headers: {
                    'Authorization': data.accessToken
                }
            })
        if (!response) {
            throw new ApiError("Opss... c'è stato un errore")
        }

        return response.data
        
    } catch {
        throw new ApiError("Errore nel ricevere la risposta del test")

    }

}

export const updateUserProfile = async (data: UpdateProfile, accessToken:string) => {

    try {
        let response = await api.put("/shopify/customer/test",
            data,
            {
                headers: {
                    'Authorization': accessToken
                }
            })
        if (!response) {
            throw new ApiError("Errore")
        }

        return response.data

    } catch {
        throw new ApiError("Errore nell'aggiornare il profilo")
    }


    
}
