import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// Router
import { Link, Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Stories from '../components/ui/ig/Stories';
import Section from '../components/ui/section/Section';
import { useAuthContext } from '../context/AuthContext';
import { useTestContext } from '../context/TestContext';
import { getUserInfoByAccessToken } from '../service/auth';
import { updateUserProfile } from '../service/test';
import { ResultText, UpdateProfile } from '../types/types';
import { ApiError } from '../utils/error';

import html2canvas from 'html2canvas';


type PossibleResult = {
    armocromia: string[],
    bodyshape: string[]
}

const possibleResult: PossibleResult = {
    "armocromia": ["spring", "summer", "winter", "autumn"],
    "bodyshape": ["triangolo", "triangolo inverso", "diamante", "trapezio", "otto", "clessidra", "rettangolo"]
}

function Result() {

    //Router
    const [searchParams] = useSearchParams();
    const { testType } = useParams()
    const navigate = useNavigate()

    //i18n
    const { t } = useTranslation();

    const printRef = useRef<HTMLDivElement | null>(null);

    // Per typescript e usare type con indice nel check result
    let type: "armocromia" | "bodyshape" | undefined;
    if (testType === "armocromia") {
        type = "armocromia"
    }
    if (testType === "bodyshape") {
        type = "bodyshape"
    }


    // Result
    const result: string | null = searchParams.get(testType === "armocromia" ? "season" : "bodyshape")


    // Context
    const { user, setUser } = useAuthContext()
    const { endTest } = useTestContext()

    // State
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)


    //check if result is valid
    if (result && type) {
        if (!possibleResult[type].includes(result.toLocaleLowerCase())) {
            return <Navigate to={"/"} />
        }
    } else {
        return <Navigate to={"/"} />
    }

    if (!user) {
        toast.error("Effettua login per avere il risultato")
    }

    if (!user) { return <Navigate to={"/"} /> }

    if (!endTest) {
        toast.error("Fai il test per avere il risultato")
    }

    if (!endTest) { return <Navigate to={"/"} /> }


    // Testi traduzioni
    let textResult: ResultText = t("result:" + testType + ":" + result, { returnObjects: true })


    const update = async () => {
        setLoadingUpdate(true)
        let data: UpdateProfile;
        if (testType === "armocromia") {
            data = {
                season: result
            }

        } else {
            data = {
                bodyshape: result
            }

        }

        try {
            const res = await updateUserProfile(data, user.accessToken)
            if (res) {
                let userUpdate = await getUserInfoByAccessToken(user.accessToken)
                if (userUpdate) {
                    setUser(userUpdate)
                }
                toast.success("Profilo aggiornato")
            }
        } catch (e) {
            if (e instanceof ApiError) {
                toast.error(e.message)
            }
        }

        setLoadingUpdate(false)
        navigate("/")
    }

    const save = async () => {
        if (printRef.current) {
            printRef.current.style.display = "block"
            const canvas = await html2canvas(printRef.current);
            const data = canvas.toDataURL('image/jpg');
            const link = document.createElement('a');

            if (typeof link.download === 'string') {
                link.href = data;
                link.download = 'image.jpg';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.open(data);
            }
            printRef.current.style.display = "none"
        };
    }

    

    return (


        <div>
            {/* SEO */}
            <Helmet
                title={'Khooa Test | Risultato'}
            />
            <div className='relative bg-gradient-to-r from-gradient-1 via-gradient-3 to-gradient-2  w-full overflow-hidden py-8 flex justify-center mb-8'>
                <div className='text-center w-full top-4'>
                    <Section>
                        <div className=''>
                            <div className=' bg-white  shadow-none text-center text-xl font-bold  py-14 mx-4 md:mx-0 rounded-lg '>
                                <p className='text-3xl font-semibold'>{user?.firstName} la tua {testType === "armocromia" ? "stagione" : "bodyshape"} è </p>
                                <p className={'first-letter:uppercase text-6xl font-extrabold ' + result}>{result}</p>
                            </div>
                        </div>
                    </Section>
                </div>
            </div>

            <Section>
                <div className='grid grid-cols-1 md:grid-cols-2 mx-4 md:mx-0 gap-4'>
                    <button className={`bg-primary text-white rounded-lg px-4 py-2 hover:shadow-xl ${loadingUpdate ? "animate-pulse" : ""}`} onClick={() => update()}>{loadingUpdate ? "Loading..." : "Aggiorna profile"}</button>
                    <button onClick={()=>save()} className='border border-primary rounded-lg px-4 py-2 hover:shadow-xl' >Scarica e condividi</button>
                </div>
            </Section>

            <Section>
                <div className='grid grid-cols-1 md:grid-cols-2 mx-4 md:mx-0 pt-4 gap-4'>

                    <div className='my-4'>
                        <p className=''>{textResult.short_description}</p>
                    </div>

                    <div>
                        {testType === "armocromia" ?
                            <img alt={result} className='rounded-lg my-4 shadow-lg' src={require(`../assets/img/palette/${textResult.image}`)} />
                            : <></>
                        }
                    </div>
                </div>
            </Section>

            <Section>
                <div className='flex justify-center my-4'>
                    <Link className='border border-primary px-8 py-2 rounded-lg' to={"/"}>Torna alla home</Link>
                </div>
            </Section>


            {
                testType === "armocromia" ?
                    <Stories  printRef={printRef} name={user.firstName} season={result!} bodyshape={user.bodyshape!} /> :
                    <Stories  printRef={printRef} name={user.firstName} season={user.season!} bodyshape={result!} />

            }
        </div>



    )
}

export default Result