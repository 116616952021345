import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

// Style
import './index.css';
import 'swiper/css';

// App
import App from './App';

// i18n
import './i18n'



// Context
import AuthContextProvider from './context/AuthContext';
import TestContextProvider from './context/TestContext';
import Loading from './components/ui/loading/Loading';
import DataContextProvider from './context/DataContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Suspense fallback={<Loading />}>

    <AuthContextProvider>
      <TestContextProvider>
        <DataContextProvider>
        <App />
        </DataContextProvider>
      </TestContextProvider>
    </AuthContextProvider>
  </Suspense>
);
