import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
  });

api.interceptors.response.use(undefined, (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
  
    if (!expectedError) {
      console.log("Logging the error", error);
    }
    return Promise.reject(error);
  });
  
  const expObj = {
    get: api.get,
    post: api.post,
    put: api.put,
    delete: api.delete
  }
  
  export default expObj