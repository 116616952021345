import React from 'react'
import Section from '../section/Section'

type Props = {
  question: string,
  value: string
}

function QuesionHeader({ question, value }: Props) {
  return (
    <div className='relative bg-gradient-to-r from-gradient-1 via-gradient-3 to-gradient-2  w-full overflow-hidden py-8 flex justify-center mb-8'>
      <div className='text-center w-full top-4'>
        <Section>
          <div className=''>
            <div className=' bg-white  shadow-none text-center text-xl font-bold  py-14 mx-4 md:mx-0 rounded-lg px-4'>
              {question}
            </div>
          </div>
        </Section>
      </div>
    </div>
  )
}

export default QuesionHeader