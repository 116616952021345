import React from 'react'
import { AnswerType, Answer } from '../../../../types/types'
import Section from '../../section/Section'
import ImageAnswer from './ImageAnswer'
import RangeAnswer from './RangeAnswer'

import TextAnswer from './TextAnswer'

type Props = {
    answers: Answer[]
    type: AnswerType,
    nextQuestionValue: string | null,
    isLastQuestion?: boolean,
    textResult?: string,
    valueResult?: string,
    selectedAnswerValue: string,
    setSelectedAnswerValue: any,
    questionValue:string
}


function Answers({ answers, type, nextQuestionValue, isLastQuestion, selectedAnswerValue, setSelectedAnswerValue, questionValue }: Props) {
    
console.log(answers.length)
    
    return (
        <Section>
            <div className=''>
                {
                    type === "range" ?

                        <RangeAnswer
                            selectedAnswerValue={selectedAnswerValue}
                            setSelectedAnswerValue={setSelectedAnswerValue}
                            answers={answers}
                            type={type}
                            questionValue={questionValue}

                        /> :
                        <div className={`grid ${answers.length > 3 ? "md:grid-cols-4" : answers.length === 3 ? "md:grid-cols-3" : "md:grid-cols-2"} grid-cols-2 gap-2 mx-4 md:mx-0 mb-16`}>
                            {
                                answers.map((answer) => {
                                    return (
                                        type === "text" ?
                                            <TextAnswer
                                                key={answer.value}
                                                textToShow={answer.textToShow}
                                                value={answer.value}
                                                type={type}
                                                selectedAnswerValue={selectedAnswerValue}
                                                setSelectedAnswerValue={setSelectedAnswerValue}

                                            />
                                            :
                                            type === "image" ?
                                                <ImageAnswer
                                                    key={answer.value}
                                                    textToShow={answer.textToShow}
                                                    value={answer.value}
                                                    image={answer.image}
                                                    type={type}
                                                    selectedAnswerValue={selectedAnswerValue}
                                                    setSelectedAnswerValue={setSelectedAnswerValue}
                                                />
                                                : <></>
                                    )
                                })
                            }</div>
                }
            </div>
        </Section>
    )
}

export default Answers