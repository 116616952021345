import React from 'react'

type Props = {
    children: JSX.Element
}

function Section({ children }: Props) {
    return (
        <div className='mx-auto max-w-6xl w-full flex flex-col'>
            {children}
        </div>
    )
}

export default Section