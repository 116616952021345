import React from 'react'
import logoWhite from '../../../assets/img/white-logo.png'


type Props = {
    name: string,
    season: string,
    bodyshape: string,
    printRef: React.MutableRefObject<HTMLDivElement | null>,
   
}

function Stories({ name, season, bodyshape,printRef }: Props) {
  
    function capitalizeFirstLetter(string:string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    let seasonUpper = capitalizeFirstLetter(season)

return (
    <div style={{display:"none"}} ref={printRef} className='h-[1920px] w-[1080px] bg-gradient-to-r from-gradient-1 via-gradient-3 to-gradient-2'>
        <div style={{paddingTop:"100px", paddingBottom:"100px"}} className='flex justify-center'><img alt='logo' src={logoWhite}/></div>
        <div className='w-full h-full flex justify-center '>
            <div className='bg-white  rounded-lg bg-opacity-90 m-4 h-[70%] w-[90%] '>
                <h1 className='text-center text-[120px] my-16'>{name}</h1>
                <div className='space-y-4'>
                <div style={{backgroundColor:"", marginRight:"100px", marginLeft:"100px", padding:"50px", display:"flex", alignItems:"center", justifyContent:"center"}} className='flex-col mx-16  rounded-lg'>
                    <p className='text-[70px]'>La tua stagione</p>
                    <p className={"text-[120px] font-bold ig-season"}>{seasonUpper}</p>
                </div>

                <div style={{backgroundColor:"", marginRight:"100px", marginLeft:"100px", padding:"50px", display:"flex", alignItems:"center", justifyContent:"center"}} className='flex-col   mx-16  rounded-lg'>


                    <p className='text-[70px]'>La tua bodyshape</p>
                    <p className={bodyshape + " text-[120px] font-bold"}>{bodyshape}</p>
                </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default Stories