import { useEffect } from 'react';
import {
    useNavigate
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../context/AuthContext';
import Loading from '../ui/loading/Loading';

type PrivateRouteProps = {
    isAuthenticated: boolean,
    children: JSX.Element
}

const PrivateRoute = ({ isAuthenticated, children }: PrivateRouteProps) => {

    //context
    const {loading, user} = useAuthContext()

    const navigate = useNavigate();
    
    useEffect(() => {
        if(!loading){
            if(!user){
                navigate("/")
                toast.error("Effettua login")

            }
        }
    })

    if (!user || loading) {
       
        return <Loading />
    }
    return children
}

export default PrivateRoute