import React, { createContext, useState, useContext } from 'react';

//types
interface DataContextProps {
    children: JSX.Element
}

interface AppContextInterface {
    showModalLogin: boolean,
    setShowModalLogin: React.Dispatch<React.SetStateAction<boolean>>

}


// context init
export const DataContext = createContext({} as AppContextInterface);
export const useDataContext = () => useContext(DataContext);

// Provider
function DataContextProvider({ children }: DataContextProps) {

    // state    
    const [showModalLogin, setShowModalLogin] = useState<boolean>(false)


    return (
        <DataContext.Provider value={{
            showModalLogin,
            setShowModalLogin,
        }}>
            {
                children
            }
        </DataContext.Provider>
    );
}

export default DataContextProvider;