import React from 'react'

function FakeArticle() {
  return (
    <div>
        <div className="group relative flex bg-gray-300 animate-pulse items-center justify-center overflow-hidden shadow-xl h-96 md:h-[35vh] rounded-2xl">
        <div className="absolute bg-gray-300 animate-pulse group-hover:scale-110 w-full h-full transition-all duration-300 ease-in-out transform bg-center bg-cover"></div>
        <div className="absolute rounded-lg top-0 mt-20 right-0 bottom-0 left-0 "></div>
        <p className="absolute break-words px-2 bottom-8 text-center font-black transition-all duration-500 ease-in-out transform text-gray-50 opacity-80 group-hover:opacity-100 group-hover:scale-105 animate-pulse h-6 bg-gray-400 rounded-lg w-[50%]"></p>
      </div>
    </div>
  )
}

export default FakeArticle