import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useTestContext } from '../context/TestContext';
import TestSection from '../components/ui/section/TestSection';
import { Helmet } from 'react-helmet';

function Test() {

    //Router
    const [searchParams] = useSearchParams();
    const { testType } = useParams()

    // Context
    const { setType } = useTestContext()

    // State
    const [fashionPreference, setFashionePreference] = useState<"man" | "woman">()

    // useEffect
    useEffect(() => {
        if (testType === "armocromia" || testType === "bodyshape") {
            setType(testType)
        }

        if(searchParams.get("preference") === "man"){
            setFashionePreference("man")
        }else if(searchParams.get("preference") === "woman"){
            setFashionePreference("woman")
        }else{
            setFashionePreference(undefined)
        }
    },[testType, searchParams, setType])

    return (
        <>
            {/* SEO */}
            <Helmet
                title={'Khooa Test | ' + testType}
            />
            {
                testType === "armocromia" ?
                    <TestSection type={"armocromia"} />
                    :

                    testType === "bodyshape" ?
                        fashionPreference ?
                            <TestSection type={"bodyshape"} fashionPreference={fashionPreference} />
                            : <div className='min-h-screen flex items-center justify-center bg-gradient-to-r from-gradient-1 via-gradient-3 to-gradient-2'>
                                    <div className='bg-white rounded-lg shadow-lg p-10 mx-4 md:mx-0'>
                                        <h2 className='text-center'>Seleziona la tua preferenza di moda:</h2>
                                        <div className='grid grid-cols-1 md:grid-cols-2 p-4 gap-2'>
                                            <Link className='text-center bg-primary text-white rounded-lg px-8 py-2 hover:bg-opacity-90 hover:scale-105 transform transition-all ease-in-out duration-300' to={"/test/bodyshape?preference=man"}>Uomo</Link>
                                            <Link className='text-center bg-primary text-white rounded-lg px-8 py-2 hover:bg-opacity-90 hover:scale-105 transform transition-all ease-in-out duration-300' to={"/test/bodyshape?preference=woman"}>Donna</Link>
                                        </div>
                                    </div>
                            </div> 
                    : <></>

            }
        </>
    )
}

export default Test