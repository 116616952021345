import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AnswerObject, AnswerSelected, Question, QuestionBodyshape, SeasonAnswer } from '../../../types/types'
import QuesionHeader from '../test/QuestionHeader';
import Answers from '../test/answers/Answers';
import FooterButton from '../test/FooterButton';
import { useNavigate } from 'react-router-dom';
import { getResultSeasonTest } from '../../../service/test';
import { useAuthContext } from '../../../context/AuthContext';
import { useTestContext } from '../../../context/TestContext';

type Props = {
  type: "bodyshape" | "armocromia",
  fashionPreference?: "man" | "woman" | string
}

function TestSection({ type, fashionPreference }: Props) {

  //i18n
  const { t } = useTranslation();

  // Context
  const { user } = useAuthContext()
  const {setLoading, setEndTest} = useTestContext()

  // Router
  const navigation = useNavigate()

  let question: Question[] | QuestionBodyshape[];
  if (type === "armocromia") {
    question = t(type + ":allQuestion", { returnObjects: true })
  } else {
    question = t(type + "_" + fashionPreference + ":allQuestion", { returnObjects: true })
  }

  // State
  const [questionIndex, setQuestionIndex] = useState<number>(0)
  const [selectedAnswerValue, setSelectedAnswerValue] = useState<string>("")
  const [nextAnswerValue, setNextAnswerValue] = useState<string>("")
  //const [previousAnswerValue, setPreviousAnswerValue] = useState<string>("")


  const [allAnswers, setAllAnswers] = useState<AnswerSelected[]>([])

  // Function
  const saveOrUpdateAnswer = () => {
    const newAnswers: AnswerSelected[] = [...allAnswers];
    let justExistThisAnswer = newAnswers.find(e => e.key === question[questionIndex].value)

    if (justExistThisAnswer) {
      // se esiste già questa risposta (ho risposto in precedenza e voglio modificare)
      justExistThisAnswer.value = selectedAnswerValue
    } else {
      // non ho mai riposto
      newAnswers.push({
        key: question[questionIndex].value,
        value: selectedAnswerValue
      })
    }

    setAllAnswers(newAnswers)
  }

  const nextAnswer = async (value: string, isLastQuestion: boolean) => {

    if (value && selectedAnswerValue && !isLastQuestion) {
      // Salvo la rispota data
      saveOrUpdateAnswer()

      // Pulisco il valore della domanda selezionata
      setSelectedAnswerValue("")

      // Setto la nuova domanda trovando l'indice
      const index = question.map(question => question.value).indexOf(value);
      setQuestionIndex(index)

      // Vision top
      window.scrollTo(0,0)

      // Setto il valore preselezionato in precedenza
      const indexOfPreviousValue = (allAnswers.map(a => a.key).indexOf(value))
      setSelectedAnswerValue(allAnswers[indexOfPreviousValue].value)

    } else {
      // ULTIMA DOMANDA 
      if (type === "armocromia") {

        setLoading(true)
        // Salvo l'ultima rispota data
        saveOrUpdateAnswer()

        // Chiamo backend e mando pagina result con /test/armocromia/result?season=summer
        // creazione obj answer
        let answerObj: AnswerObject= {};

        allAnswers.forEach(answer => {
          answerObj[answer.key] = answer.value
        });

        let data: SeasonAnswer = {
          test_response: answerObj
        }
        const result = await getResultSeasonTest({
          accessToken: user!.accessToken,
          test_response: data
        })

         //Finito il test
         setEndTest(true)


        setLoading(false)
        navigation("/test/armocromia/result?season="+result.season)

      } else {
       
        // Salvo l'ultima rispota data
        saveOrUpdateAnswer()

        //Finito il test
        setEndTest(true)

        // Mando pagina result con /test/bodyshape/result?bodyshape=rettangolo
        const indexOfValueResult = (question[questionIndex].answer.map(a => a.value).indexOf(selectedAnswerValue))
        let result = question[questionIndex].answer[indexOfValueResult].valueResult
        navigation("/test/bodyshape/result?bodyshape="+result)
      }

      

    }

  }

  const previousAnswer = (value: string) => {
    if (value) {
      // Trovo la domanda precedente
      const index = question.map(question => question.value).indexOf(value);
      setQuestionIndex(index)

      // Setto il valore preselezionato in precedenza
      const indexOfPreviousValue = (allAnswers.map(a => a.key).indexOf(value))
      setSelectedAnswerValue(allAnswers[indexOfPreviousValue].value)

      // Vision top
      window.scrollTo(0,0)

      // Se viene modificata la risposta e clicco ancora indietro devo tenerne conto
      saveOrUpdateAnswer()

    } else {
      // PRIMA DOMANDA
      navigation("/")
    }
  }


  useEffect(()=>{
    const indexOfNextValue = (question[questionIndex].answer.map(a => a.value).indexOf(selectedAnswerValue))
    console.log(indexOfNextValue)
    if(indexOfNextValue >= 0){
      setNextAnswerValue(question[questionIndex].answer[indexOfNextValue].nextQuestionValue!)
      //setPreviousAnswerValue(question[questionIndex].answer[indexOfNextValue].previousQuestionValue!)
    }
    
  },[selectedAnswerValue,question,questionIndex])

  return (
    <div className='bg-lightGray min-h-screen'>
      {/* QUESION */}
      <QuesionHeader question={question[questionIndex].textToShow} value={question[questionIndex].value} />

      {/* ANSWER */}
      {
        <Answers
          key={question[questionIndex].value}
          answers={question[questionIndex].answer}
          type={question[questionIndex].type}
          nextQuestionValue={nextAnswerValue}
          isLastQuestion={question[questionIndex].isLastQuestion}
          textResult={question[questionIndex].textResult}
          valueResult={question[questionIndex].valueResult}
          selectedAnswerValue={selectedAnswerValue}
          setSelectedAnswerValue={setSelectedAnswerValue}
          questionValue={question[questionIndex].value}
        />
      }
    

      {/* FOOTER */}
      <FooterButton
        nextAnswer={nextAnswer}
        nextAnswerValue={nextAnswerValue}
        previousAnswerValue={question[questionIndex].previousQuestionValue}
        previousAnswer={previousAnswer}
        selectedAnswerValue={selectedAnswerValue}
        isLastQuestion={question[questionIndex].isLastQuestion}
      />


    </div>
  )
}

export default TestSection