import React from 'react';

// Router
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Pages
import Home from './pages/Home';
import Result from './pages/Result';
import Test from './pages/Test';

//notifly
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Context
import { useAuthContext } from './context/AuthContext';

import PrivateRoute from './components/routes/PrivateRoute';
import { useTestContext } from './context/TestContext';
import Loading from './components/ui/loading/Loading';
import LoginForm from './components/ui/form/LoginForm';
import { useDataContext } from './context/DataContext';
import SelectTest from './components/ui/section/SelectTest';
import ZoomColor from './components/ui/test/ZoomColor';


function App() {

  // Context
  const { isAuthenticated } = useAuthContext()
  const { loading } = useTestContext()
  const { setShowModalLogin } = useDataContext()


  return (
    <div className="bg-white dark:bg-primary text-primaryText dark:text-white pb-8">

      <ToastContainer className={"rounded-2xl"} style={{ borderRadius: '200px', paddingRight:"1rem", paddingLeft:"1rem", paddingBottom:"1rem" }} position={"bottom-center"} />
 
      {
        loading ? <Loading /> :
          <>
            <ZoomColor />
            <LoginForm onClose={setShowModalLogin}/>
            <BrowserRouter>
              <Routes>
                {/* HOME */}
                <Route path='/' element={<Home />} />

                {/* TEST e RESULT */}
                <Route path='test'>
                  
                  <Route path=':testType' element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <Test />
                    </PrivateRoute>
                  } />

                  <Route path=':testType/result' element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <Result />
                    </PrivateRoute>
                  } />

                  <Route index element={<SelectTest />}/>

                </Route>


              </Routes>
            </BrowserRouter>
          </>
      }
    </div>
  );
}

export default App;
