import React from 'react'
import { AnswerType } from '../../../../types/types'


type Props = {
    textToShow: string,
    value: string,
    type: AnswerType,
    selectedAnswerValue?: string,
    setSelectedAnswerValue?: any,
    image?:string
}

function ImageAnswer({textToShow,value,type, selectedAnswerValue, setSelectedAnswerValue, image}: Props) {
  return (
    <div onClick={()=>setSelectedAnswerValue(value)} className={` ${selectedAnswerValue === value ? "border-black" : ""} text-center grid grid-cols-1 flex-col gap-1 p-2 shadow-lg rounded-lg hover:selected border  cursor-pointer hover:shadow-2xl group`}>
      <div className='p-0.4 md:p-2'>
        <img alt={textToShow} className='rounded' src={require(`../../../../assets/img/${image}`)}/>
        </div>
        <p className=' group-hover:scale-105 transition-all ease-in-out duration-500 transform'>{textToShow}</p>
    </div>
  )
}

export default ImageAnswer