import api from '../service/index'
import { User, Login, AccessToken } from '../types/types'
import { ApiError } from '../utils/error'

const getAccesToken = async (data: Login): Promise<AccessToken> => {
    try {
        let response = await api.post("/shopify/customer/login", data)

        if (!response) {
            throw new ApiError("errore")
        }

        return {
            customerAccessToken: {
                accessToken: response.data.customerAccessToken.accessToken,
                expiresAt: response.data.customerAccessToken.expiresAt
            }
        }
    } catch (e) {
        throw new ApiError("errore")
    }
}

export const getUserInfo = async (accessToken: AccessToken): Promise<User> => {
    try {
        let response = await api.get("/shopify/customer", {
            headers: {
                'Authorization': accessToken.customerAccessToken.accessToken
            }
        })

        if (!response) {
            throw new ApiError("errore")
        }

        console.log(response)

        let user: User = {
            firstName: response.data.customer.firstName,
            lastName: response.data.customer.lastName,
            email: response.data.customer.email,
            season: response.data.customer.stagione.value,
            bodyshape: response.data.customer.bodyshape.value,
            fashionPreference: response.data.customer.preferenza_moda.value,
            idCustomer: response.data.customer.idCustomer,
            accessToken: accessToken.customerAccessToken.accessToken,
            expiredAt: accessToken.customerAccessToken.expiresAt
        }
        return user
    } catch (e) {
        throw new ApiError("errore")
    }
}

export const login = async (data: Login): Promise<User> => {

    try {
        let accessToken: AccessToken = await getAccesToken(data)

        if (accessToken) {
            try {

                let user: User = await getUserInfo(accessToken)
                if (!user) {
                    throw new ApiError("Email e password sbagliate.")
                }
                return user

            } catch (error) {
                throw new ApiError("Opss, c'è stato un errore. Riprova.")
            }
        } else {
            throw new ApiError("Email e password sbagliate.")
        }
    } catch (e) {
        throw new ApiError("Email e password sbagliate.")
    }
}


export const getUserInfoByAccessToken = async (accessToken: string): Promise<User | undefined> => {
    try {
        let response = await api.get("/shopify/customer", {
            headers: {
                'Authorization': accessToken
            }
        })

        console.log(response)

        let user: User = {
            firstName: response.data.customer.firstName,
            lastName: response.data.customer.lastName,
            email: response.data.customer.email,
            season: response.data.customer.stagione.value,
            bodyshape: response.data.customer.bodyshape.value,
            fashionPreference: response.data.customer.preferenza_moda.value,
            idCustomer: response.data.customer.idCustomer,
            accessToken: accessToken
        }

        return user
    } catch (e) {
        return undefined
    }
}


