import React from 'react'

type Props = {
  title: string,
  image: string,
  url: string,
  tags: string
}

const REACT_APP_SHOPIFY_BLOG_BASE_URL =  process.env.REACT_APP_SHOPIFY_BLOG_BASE_URL

function ArticleCard({ title, image, url, tags }: Props) {

 // const arrayTags:string[] = tags.split(",")
  
  return (
    <a href={REACT_APP_SHOPIFY_BLOG_BASE_URL+url} target="_blank" rel="noreferrer">
      <article className=" group relative flex items-center justify-center overflow-hidden shadow-xl h-96 md:h-[35vh] rounded-2xl">
        <div style={{ backgroundImage: `url(${image})` }} className="absolute group-hover:scale-110 w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover"></div>
        <div className="absolute rounded-lg top-0 mt-20 right-0 bottom-0 left-0 bg-gradient-to-b from-transparent to-gray-900"></div>
        
        <h4 className="absolute break-words px-2 bottom-10 text-center font-black transition-all duration-500 ease-in-out transform text-gray-50 opacity-80 group-hover:opacity-100 group-hover:scale-105">{title}</h4>
      </article>
    </a>
  )
}

export default ArticleCard