import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useAuthContext } from '../../../context/AuthContext'
import { useDataContext } from '../../../context/DataContext'
import { login } from '../../../service/auth'
import { User } from '../../../types/types'
import { ApiError } from '../../../utils/error'

type Props = {
    onClose: (value: boolean) => void
}

function LoginForm({ onClose }: Props) {

    // env
    let COOKIE_NAME: string | undefined = process.env.REACT_APP_COOKIE_NAME

    // contect
    const { setUser } = useAuthContext()
    const { showModalLogin } = useDataContext()

    //state
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>("SP9AsGKmh9iPGNU")
    const [loading, setLoading] = useState<boolean>(false)

    // function
    const loginHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)

        let data = {
            email: email,
            password: password
        }

        try {
            let response: User = await login(data)
            if (response) {

                let now = new Date()
                let dateExpired = new Date(response.expiredAt!)
                var diff = Math.abs(now.getTime() - dateExpired.getTime());
                var expiredAt = Math.ceil(diff / (1000 * 3600 * 24));

                Cookies.set(COOKIE_NAME!, response.accessToken, { expires: expiredAt })

                setUser({
                    firstName: response.firstName,
                    lastName: response.lastName,
                    email: response.email,
                    season: response.season,
                    bodyshape: response.bodyshape,
                    fashionPreference: response.fashionPreference,
                    idCustomer: response.idCustomer,
                    accessToken: response.accessToken,
                    expiredAt: response.expiredAt
                })

                toast.success("Ciao " + response.firstName)
                setLoading(false)
                onClose(false)
            } else {
                // error
                toast.error("Opss, c'è stato un errore")

            }
        } catch (e) {
            // error
            if (e instanceof ApiError) {
                toast.error(e.message)
            }
        }


    }



    return (
        <div>
            {
                !showModalLogin ? <></> :

                    <div className='bg-black bg-opacity-75 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-screen'>
                        <div className='relative w-full my-6 mx-4 max-w-xl text-dark'>
                            <div className='p-4 border-2 border-opacity-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white border-white outline-none focus:outline-none'>
                                {/* HEADER */}
                                <div className='flex flex-col items-center mb-8'>
                                    <h2>Login</h2>
                                </div>

                                {/* BODY */}
                                <div className='flex flex-col items-center m-2 w-full'>
                                    <form onSubmit={(e) => loginHandler(e)} className='space-y-4 w-full md:max-w-xl'>
                                        {/* EMAL */}
                                        <div className='flex flex-col space-y-2'>
                                            <label htmlFor="email">Email</label>
                                            <input value={email} onChange={(e) => setEmail(e.target.value)} className='input' required type="email" id="email" name="email" />
                                        </div>

                                        {/* PASSWORD */}
                                        <div className='flex flex-col space-y-2'>
                                            <label htmlFor="password">Password</label>
                                            <input value={password} onChange={(e) => setPassword(e.target.value)} className='input' required type="password" id="password" name="password" />
                                        </div>


                                        {/* BUTTON */}
                                        <div className='flex flex-col space-y-2'>
                                            <button disabled={loading} type='submit' className='bg-primary text-white p-2 rounded-lg'>
                                                {loading ?
                                                    <div className='flex justify-center items-center'>
                                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        Loading...
                                                    </div>
                                                    : "Login"}</button>
                                        </div>
                                    </form>
                                    {/* BUTTON */}
                                    <div className='flex flex-col space-y-8 w-full mt-2 md:max-w-xl'>
                                        <button onClick={() => onClose(false)} className='p-2 rounded-lg border border-primaryText'>Chiudi</button>
                                        <p className='font-medium'>
                                            Non hai un account? <a className='text-blue-500' target="_blank" rel="noreferrer" href='https://khooa.com/account/register'>Registrati qui</a>
                                        </p>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default LoginForm