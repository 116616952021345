import { Link } from "react-router-dom"

function SelectTest() {
    return (
        <div className='min-h-screen flex items-center justify-center bg-gradient-to-r from-gradient-1 via-gradient-3 to-gradient-2'>
            <div className='bg-white rounded-lg shadow-lg p-10 mx-4 md:mx-0'>
                <h2 className='text-center'>Seleziona il test:</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 p-4 gap-2'>
                    <Link className='text-center bg-primary text-white rounded-lg px-8 py-2 hover:bg-opacity-90 hover:scale-105 transform transition-all ease-in-out duration-300' to={"/test/armocromia"}>Armocromia</Link>
                    <Link className='text-center bg-primary text-white rounded-lg px-8 py-2 hover:bg-opacity-90 hover:scale-105 transform transition-all ease-in-out duration-300' to={"/test/bodyshape"}>Bodyshape</Link>
                </div>
            </div>
        </div>
    )
}

export default SelectTest