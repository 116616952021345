import React from 'react'
import Articles from '../components/ui/section/Articles'
import Header from '../components/ui/header/Header'

// Seo
import {Helmet} from "react-helmet";

function Home() {

  

  return (
    <>
        {/* SEO */}
        <Helmet 
          title='Khooa Test | Home'
        />
    
        {/* HERO */}
        <Header />
      
        {/* LAST NEWS */}
        <Articles />

        {/* FOOTER */}

    </>
  )
}

export default Home