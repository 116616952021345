import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';

//transation

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        ns: ['common', 'armocromia', 'bodyshape_man', 'bodyshape_woman', 'result'],
        fallbackLng: ['it', 'en'],
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;