import React, { useState, useLayoutEffect, useRef } from 'react'
import { useTestContext } from '../../../../context/TestContext';
import { Answer, AnswerType } from '../../../../types/types'
import ImageWithLoading from '../../image/ImageWithLoading';

type Props = {
    answers: Answer[],
    type: AnswerType,
    selectedAnswerValue?: string,
    setSelectedAnswerValue?: any,
    questionValue: string
}

function RangeAnswer({ answers, type, selectedAnswerValue, setSelectedAnswerValue, questionValue }: Props) {

    // State
    const [answerIndex, setAnswerIndex] = useState<number>(0);


    // Context
    const {setZoomColor, setColor} = useTestContext()

    // Ref
    const range = useRef<HTMLInputElement | null>(null)
    const p = useRef<HTMLInputElement | null>(null)
    const span = useRef<HTMLInputElement | null>(null)

    // Function
    const openZoom = (hex:string) => {
        setColor(hex)
        setZoomColor(true)
    }



    //Functions
    const changeSlider = (i: any) => {
        setAnswerIndex(i)
        setSelectedAnswerValue(answers[i].value)
        if (p && span) {
            if (p.current && span.current) {
                if (answers[i].hex) {
                    let hex: string = answers[i].hex!
                    p.current.style.borderColor = hex
                    span.current.style.borderColor = hex
                    span.current.style.backgroundColor = hex
                }
            }
        }
    }


    useLayoutEffect(() => {
        if (selectedAnswerValue) {
            const index = answers.map(a => a.value).indexOf(selectedAnswerValue);
            setAnswerIndex(index)
            if (p && span) {
                if (p.current && span.current) {
                    if (answers[index].hex) {
                        let hex: string = answers[index].hex!
                        p.current.style.borderColor = hex
                        span.current.style.borderColor = hex
                        span.current.style.backgroundColor = hex

                    }
                }
            }
        }

    }, [selectedAnswerValue, answers])

    return (
        <>
        
        <div className='text-center flex flex-col space-y-4 justify-center items-center'>
            <div className='flex items-center space-x-4'>
                <p ref={p} className={'px-8 py-2 bg-white rounded-3xl shadow-lg border-2'}>{answers[answerIndex].textToShow}</p>
                <span ref={span} onClick={()=>{openZoom(answers[answerIndex].hex!)}} className={'h-8 w-8 bg-white rounded-3xl shadow-lg border-2 animate-bounce cursor-zoom-in'}>&#x1F50D;</span>
            </div>
            <ImageWithLoading hex={answers[answerIndex].hex!} src={require(`../../../../assets/img/${answers[answerIndex].image}`)}/>
           {/* <img loading={"lazy"} alt={answers[answerIndex].value} className={'rounded-full w-[75vw] md:w-[15vw]'} src={require(`../../../../assets/img/${answers[answerIndex].image}`)} /> */}
            <input ref={range} id={questionValue} className='md:w-2/4 w-3/4' type="range" value={answerIndex} min={0} max={answers.length - 1} step={1} onChange={(e) => changeSlider(e.target.value)} />
        </div>
        </>
    )
}

export default RangeAnswer