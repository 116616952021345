import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../../context/AuthContext'
import Section from './Section'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { Navigation, Autoplay, Pagination } from "swiper";
import ArticleCard from '../card/ArticleCard';
import { Article, SeasonValue } from '../../../types/types';
import { getArticleByTag } from '../../../service/data';
import FakeArticle from '../card/FakeArticle';
import { useTranslation } from 'react-i18next';


const fakeArticle = [
  1, 2, 3, 4, 5, 6
]

function Articles() {

  //context
  const { isAuthenticated, user } = useAuthContext()

  //state
  const [articles, setArticles] = useState<Article[]>()

  //i18n
  const {t} = useTranslation()


  useEffect(() => {

    const getArticle = async () => {
      let filter: SeasonValue | undefined = undefined;
      if (user) {
        filter = user.season
      }
      const res = await getArticleByTag(filter);
      if (res) {
        setArticles(res)
      }
    }

    getArticle()
  }, [user])


  return (
    <div className='my-8 md:my-16'>
      <Section>
        <>
          <div className='md:mx-0 mx-8 text-center'>
            {
              isAuthenticated ? <h2>{t("common:ourArticleLogin")} <span className='first-letter:uppercase'>{user?.season}</span></h2> : <h2>{t("common:ourArticle")}</h2>
            }
          </div>
        </>
      </Section>
      <div className='mx-4 md:mx-0'>
        {/* MAP ALL THE ARTICLE  */}
        <Swiper
          loop={true}

          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}

          centeredSlides={true}

          navigation={true}
          breakpoints={{
            // when window width is <= 499px
            299: {
              slidesPerView: 1.26,
              spaceBetween: 20,
            },
            499: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            // when window width is <= 999px
            999: {
              slidesPerView: 3,
              spaceBetween: 30

            },

            1399: {
              slidesPerView: 4,
              spaceBetween: 30

            }
          }}

          modules={[Navigation, Autoplay, Pagination]}
          className="mt-8">
          {
            articles ?
              articles?.map((article) => {
                return (
                  <SwiperSlide key={article.id}>
                    <ArticleCard title={article.title} image={article.image.src} url={article.handle ? article.handle : "/"} tags={article.tags} />
                  </SwiperSlide>
                )
              }) :
              fakeArticle?.map((value) => {
                return (
                  <SwiperSlide key={value}>
                    <FakeArticle />
                  </SwiperSlide>
                )
              })
          }
        </Swiper>
      </div>







    </div>
  )
}

export default Articles