import React from 'react'
import Section from '../section/Section'


function FakeCard() {
  return (
    
    <Section>
   
        <div className='mx-4 md:mx-0 h-[30vh] bg-white glass animate-pulse rounded-lg flex justify-center items-center'>
            <p className='animate-pulse'>Loading...</p>
        </div>
  
    </Section>
    
  )
}

export default FakeCard