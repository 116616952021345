import React, { useState } from 'react'

// Drawer
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

// Icon
import { Bars3Icon, UserCircleIcon } from '@heroicons/react/24/solid'

// Img
import logoWhite from '../../../assets/img/white-logo.png'
import { useAuthContext } from '../../../context/AuthContext'
import ProfileCard from '../card/ProfileCard'
import LoginForm from '../form/LoginForm'
import Section from '../section/Section'
import { useDataContext } from '../../../context/DataContext'
import FakeCard from '../card/FakeCard'
import { useTranslation } from 'react-i18next'
import LanguageSwitch from '../switch/LanguageSwitch'



function Header() {

    // Env
    const SITE_NAME = process.env.REACT_APP_SITE_NAME

    // i18n
    const {t} = useTranslation()

    // Context
    const { isAuthenticated, user, loading, logout } = useAuthContext()
    const {setShowModalLogin} = useDataContext()

    // State
    const [drawerIsOpenMobile, setDrawerIsOpenMobile] = useState<boolean>(false)
    const [drawerIsOpenDesktop, setDrawerIsOpenDesktop] = useState<boolean>(false)

    // Function
    const toggleDrawerMobile = () => {
        setDrawerIsOpenMobile((drawerIsOpenMobile) => !drawerIsOpenMobile)
    }

    const toggleDrawerDesktop = () => {
        setDrawerIsOpenDesktop((drawerIsOpenDesktop) => !drawerIsOpenDesktop)
    }

    return (
        <header className='background-animate bg-gradient-to-r from-gradient-1 via-gradient-3 to-gradient-2 w-full'>

            {/* MOBILE DRAWER */}
            <Drawer size={"65%"}  open={drawerIsOpenMobile} direction={"right"} onClose={toggleDrawerMobile}>
                <div className=''>
                    {
                        isAuthenticated ?
                            <div className='flex flex-col items-center space-y-4'>
                                <p className='text-center pt-8 font-semibold text-xl'>{user?.firstName}</p>
                                <button onClick={() => {logout();toggleDrawerMobile()}} className='text-red-500 border border-red-500 px-16 py-2 rounded-lg'>Logout</button>
                            </div>

                            :

                            <div className='mx-4 flex flex-col space-y-2 mt-8'>
                                <button onClick={()=>{setDrawerIsOpenMobile(false);setShowModalLogin(true)}} className='p-2 w-full rounded-lg border bg-primaryText text-white'>Login</button>
                                <p className='font-medium text-center'>
                                    Non hai un account? <a className='text-blue-500' target="_blank" rel="noreferrer" href='https://khooa.com/account/register'>Registrati qui</a>
                                </p>
                            </div>
                    }
                    <div className='flex justify-center mt-4'>
                    <LanguageSwitch />
                    </div>
                </div>
            </Drawer>

            {/* DESKTOP DRAWER  */}
            <Drawer open={drawerIsOpenDesktop} direction={"top"} onClose={toggleDrawerDesktop} size="20vh">
                <div>
                
                    {
                        isAuthenticated ?
                            <div className='flex flex-col items-center space-y-4'>
                                <p className='text-center pt-8 font-semibold text-xl'>{user?.firstName}</p>
                                <button onClick={() => {logout();toggleDrawerDesktop()}} className='text-red-500 border border-red-500 px-16 py-2 rounded-lg'>Logout</button>
                            </div>

                            :

                            <div>
                                <LoginForm onClose={toggleDrawerDesktop} />
                            </div>
                    }
                </div>
            </Drawer>


            <div className='pb-8'>
                <div className=''>
                <div className='grid grid-cols-3 py-4 items-center  '>
                    
                    <div className=''></div>
                    <div className='flex justify-center md:py-8 w-full'>
                        <img alt={"Logo " + SITE_NAME} className='w-full md:max-w-[16vw]' src={logoWhite} />
                    </div>
                    {/* mobile */}
                    <div onClick={() => !loading ? toggleDrawerMobile() : console.log("")} className="flex justify-end p-4 md:hidden">
                        <Bars3Icon className='h-10 text-white rounded-lg' />
                    </div>
                    {/* desktop */}
                    <div className="md:flex justify-end mr-8 p-4 hidden items-center">
                    <LanguageSwitch />
                    <div onClick={() => loading ? console.log() : isAuthenticated ? toggleDrawerDesktop() : setShowModalLogin(true)} className="md:flex justify-end mr-8 p-4 hidden items-center cursor-pointer group">
                        <div className='bg-white flex items-center p-1 rounded-lg hover:shadow-lg'>
                            <UserCircleIcon className='h-10 rounded-lg' /> 
                                <span className='text-primaryText ml-2 pr-2'>
                                    {
                                        loading ? <span className='animate-pulse'>...</span> :
                                            isAuthenticated ? user?.firstName : "Login"
                                    }
                                </span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {
                   
                    isAuthenticated ?
                        <div className='flex justify-center'>
                            <ProfileCard name={user?.firstName} season={user?.season} fashionPreference={user?.fashionPreference} bodyshape={user?.bodyshape} />
                        </div> :

                        loading ? <div className='flex justify-center'>
                            <FakeCard />
                        </div> :
                        
                        <Section>
                        <div className='glass p-10 rounded-lg mx-4 flex flex-col space-y-4 justify-center items-center'>
                            <h2 className='text-white font-bold text-center'>{t("common:heroTitleNoLogin")}</h2>
                            <button onClick={()=>setShowModalLogin(true)} className='p-2 md:w-1/2 w-full rounded-lg bg-primaryText text-white shadow-lg hover:scale-105 transition-all duration-300'>Login</button>

                        </div>
                        </Section>
                }
            </div>
        </header>
    )
}

export default Header