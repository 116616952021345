import React, { useLayoutEffect, useRef } from 'react'
import { useTestContext } from '../../../context/TestContext'


function ZoomColor() {

    //Ref
    const bg = useRef<HTMLInputElement | null>(null)

    // State
    const { zoomColor, color, setZoomColor } = useTestContext()

    // 
    useLayoutEffect(()=>{
        if (bg) {
            if (bg.current) {
                if (color) {
                    bg.current.style.backgroundColor = color
                }
            }
        }
    },[color, zoomColor])

    return (
        <>
            {
                zoomColor && color ?
                    <div ref={bg} className={'fixed z-10 h-screen w-screen flex items-center flex-col justify-between bg-white'}>
                        <p className='mt-8 text-white text-3xl font-bold'>Khooa color zoom</p>
                        <button className='border border-primary w-3/4 md:w-2/4 px-8 py-2 rounded-lg bg-white mb-8' onClick={()=>{setZoomColor(false)}}>Chiudi</button>
                    </div> : null
            }

        </>
    )
}

export default ZoomColor