import React from 'react'
import { useTranslation } from 'react-i18next';

// router
import { Link } from 'react-router-dom'
import { BodyshapeValue, SeasonValue } from '../../../types/types';

type Props = {
    name: string | undefined,
    season: SeasonValue | undefined,
    bodyshape: BodyshapeValue | undefined,
    fashionPreference: any
}


function ProfileCard({ name, season, bodyshape }: Props) {

    const {t} = useTranslation();

    return (
        <div className=' p-10 rounded-lg flex flex-col space-y-4 mx-4'>
            {/* PERSONAL DATA */}
           
            <h3 className='text-4xl text-center font-bold text-white'>{t("common:hello")} {name}</h3>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>

                <div className='flex items-center shadow-lg flex-col justify-center bg-lightGray rounded-lg bg-opacity-60 p-4'>
                    <p className='text-center text-lg'>{t("common:yourSeason")}</p>
                    {
                        season ? 
                        <div className='flex flex-col justify-center items-center'>
                            <p className={'font-extrabold text-5xl p-4 first-letter:uppercase text-gradient '+ season}>{season}</p> 
                            <Link to={"/test/armocromia"} className="py-1 hover:scale-105 duration-300 px-2 bg-white hover:shadow-xl rounded-lg text-center dark:text-primaryText">
                                Rifai il test
                            </Link>
                        </div>
                        :
                            <Link to={"/test/armocromia"} className="py-4 hover:scale-105 duration-300 px-8 bg-white hover:shadow-xl rounded-lg text-center dark:text-primaryText">
                                Scopri la tua Stagione
                            </Link>
                    }

                </div>


                <div className='flex items-center shadow-lg flex-col justify-center bg-lightGray rounded-lg bg-opacity-60 p-4'>
                    <p className='text-center text-lg'>{t("common:yourBodyshape")}</p>
                    {
                        bodyshape ? 
                        <div className='flex flex-col justify-center items-center'>
                            <p className={'font-extrabold text-5xl p-4 first-letter:uppercase text-gradient text-center '+ bodyshape}>{bodyshape}</p> 
                            <Link to={"/test/bodyshape"} className="hover:scale-105 duration-300 py-1 px-2 bg-white hover:shadow-xl rounded-lg text-center dark:text-primaryText">
                                Rifai il test
                            </Link>
                        </div>
                        :
                            <Link to={"/test/bodyshape"} className="py-4 hover:scale-105 duration-300 px-8 bg-white hover:shadow-xl rounded-lg text-center dark:text-primaryText">
                                Scopri la tua Bodyshape
                            </Link>
                    }

                </div>
            </div>
       

        </div>
    )
}

export default ProfileCard