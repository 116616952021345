import React, { createContext, useState, useContext } from 'react';
import { TestType } from '../types/types';

//types
interface TestContextProps {
    children: JSX.Element
}

interface AppContextInterface {
    type?: TestType | undefined,
    endTest: boolean,
    loading: boolean,
    zoomColor: boolean,
    color: string,
    setType: React.Dispatch<React.SetStateAction<TestType | undefined>>,
    setEndTest: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setZoomColor: React.Dispatch<React.SetStateAction<boolean>>,
    setColor: React.Dispatch<React.SetStateAction<string>>,


}


// context init
export const TestContext = createContext({} as AppContextInterface);
export const useTestContext = () => useContext(TestContext);

// Provider
function TestContextProvider({children}:TestContextProps) {

 // state    
 const [type, setType] = useState<TestType | undefined>()
 const [endTest, setEndTest] = useState<boolean>(false)
 const [loading, setLoading] = useState<boolean>(false)
 const [zoomColor, setZoomColor] = useState<boolean>(false)
 const [color, setColor] = useState<string>("")


    return (
        <TestContext.Provider value={{
            type,
            endTest,
            loading,
            zoomColor,
            color,
            setType,
            setEndTest,
            setLoading,
            setZoomColor,
            setColor
        }}>
            {
                children
            }
        </TestContext.Provider>
    );
}

export default TestContextProvider;